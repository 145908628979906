import React from "react"

export const TitleUnderlineNavbarVerticalOne = ({ underline }) => (
  <span>
    {underline}
    <svg style={{ bottom: "-2px" }} width="241" height="11" viewBox="0 0 241 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.8057 11C39.8231 9.97886 3.33954 11.1189 0.687696 10.8111C-1.47729 10.3985 1.98254 3.13158 3.99214 2.06147C4.69654 1.6768 5.51488 1.62086 7.38981 1.79572C10.3628 2.12444 48.8041 1.47397 48.8041 1.47397C49.3117 1.69778 50.8345 0.942419 51.1141 1.21519C51.9946 1.85166 114.303 0.98438 119.617 0.949409C122.838 0.928427 120.29 1.36206 124.081 1.18721C132.161 0.837501 149.771 1.17322 155.893 0.774556C160.793 0.445832 161.849 0.431854 166.552 0.697631C171.359 0.935431 196.914 0.592715 199.835 0.0821436C200.55 -0.0577392 201.099 -0.00877595 201.099 0.152089C201.099 0.18706 219.113 0.305956 219.195 0.43185C219.33 0.592715 219.879 0.669646 220.387 0.55774C221.329 0.38988 240.503 -0.218611 240.804 0.249996C241.746 1.59287 239.105 7.31407 236.878 8.7059C234.599 10.1957 222.086 8.52407 212.027 9.41932L165.195 9.51025L155.158 10.0698C154.008 9.58717 149.585 10.8042 146.912 9.93688C145.783 9.59416 137.807 9.5452 136.046 9.90889C134.513 10.1887 93.7094 9.48225 89.5244 10.4544C82.0661 9.64312 51.8185 10.8042 44.8057 11Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineNavbarVerticalTwo = ({ underline }) => (
  <span>
    {underline}
    <svg style={{ bottom: "-2px" }} width="221" height="11" viewBox="0 0 221 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.0873 11C36.5183 9.97886 3.0624 11.1189 0.630625 10.8111C-1.35469 10.3985 1.81802 3.13158 3.66084 2.06147C4.30678 1.6768 5.05721 1.62086 6.77655 1.79572C9.5028 2.12444 44.754 1.47397 44.754 1.47397C45.2195 1.69778 46.6158 0.942419 46.8723 1.21519C47.6797 1.85166 104.817 0.98438 109.69 0.949409C112.644 0.928427 110.307 1.36206 113.784 1.18721C121.193 0.837501 137.342 1.17322 142.956 0.774556C147.449 0.445832 148.418 0.431854 152.73 0.697631C157.138 0.935431 180.572 0.592715 183.251 0.0821436C183.907 -0.0577392 184.41 -0.00877595 184.41 0.152089C184.41 0.18706 200.929 0.305956 201.005 0.43185C201.128 0.592715 201.632 0.669646 202.097 0.55774C202.962 0.38988 220.545 -0.218611 220.82 0.249996C221.684 1.59287 219.262 7.31407 217.22 8.7059C215.13 10.1957 203.655 8.52407 194.432 9.41932L151.486 9.51025L142.281 10.0698C141.227 9.58717 137.171 10.8042 134.72 9.93688C133.685 9.59416 126.37 9.5452 124.756 9.90889C123.35 10.1887 85.9327 9.48225 82.095 10.4544C75.2557 9.64312 47.5182 10.8042 41.0873 11Z" fill="#FFBA00" />
    </svg>
  </span>
)
